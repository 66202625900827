import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        greyColorVarient1: "#f3f3f3",
        greyColorVarient2: "#d9d9d9",
        greyColorVarient3: "#9e9e9e",
        greyColorVarient4: "#808080",
        greyColorVarient5: "#606060",

        greenColorVariant1: "#cefcce",

        blueColorVariant1: "#003A9F",
        blueColorVarient2: "#ccd7eb",

        redColorVariant1: "#FFE5E5",
      },
    },
  },
});
