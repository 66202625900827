<template>
  <v-app :style="{ background: customBackgroundColor }">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    customBackgroundColor: "#f3f3f3",
  }),
};
</script>

<style>
.TableClassHeader table th{
  font-size: 0.9375rem !important;
}
.FontSize10px {
  font-size: 0.625rem !important;
}
.FontSize11px {
  font-size: 0.6875rem !important;
}
.FontSize12px {
  font-size: 0.75rem !important;
}
.FontSize18px {
  font-size: 1.125rem !important;
}
.FontSize22px {
  font-size: 1.375rem !important;
}
.FontSize25px {
  font-size: 1.5625rem !important;
}
.FontSize30px {
  font-size: 1.875rem !important;
}
.FontSize40px {
  font-size: 2.5rem !important;
}
.FontSize50px {
  font-size: 3.125rem !important;
}
.FontSize60px {
  font-size: 3.75rem !important;
}
.FontWeightVarient1 {
  font-weight: bolder !important;
}
.RoundBorderVarient1 {
  border-radius: 0.625rem !important;
}
.LetterSpacing2px {
  letter-spacing: 0.125rem !important;
}
.fontStyleVarient1 {
  font-family: Arial, Helvetica, sans-serif !important;
}
.maxWidthVariant1 {
  max-width: 12.5rem !important;
}
.vue2Editor{
  height: 21.875rem !important;
}
.custom-scrollbar {
  overflow-y: auto !important;
  /* scrollbar-width: thin !important; */
  scrollbar-color: transparent !important;
  scrollbar-width: none !important;
  /* scrollbar-color: #003a9f #f5f5f5 !important; */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem !important;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #003a9f !important;
  border-radius: 0.25rem !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #003a9f !important;
}
.v-text-field .v-input_control .v-input_slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.eventCard {
  display: inline-flex !important;
  height: 2.9375rem !important;
  padding: 0.374rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.187rem !important;
  flex-shrink: 0 !important;
  border-radius: 0.25rem !;
  background: var(--secondary-navy, #003a9f) !important;
}
.buttonClassVariant1 {
  display: flex !important;
  height: 3.5rem !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2.5rem !important;
  background: var(--primary-royal-blue, #0354e0) !important;
}
.buttonClassVariant2 {
  display: flex !important;
  height: 3.5rem !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2.5rem !important;
}
.headerVariantOne {
  color: var(--primary-black, #000) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontFamilyInter {
  font-family: 'Inter', sans-serif !important;
}
.fontStyleVariant3 {
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.CursorPointer{
  cursor: pointer !important;
}
.fontStyleVariant9 {
  color: var(--primary-royal-blue, #0354e0) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0175rem !important;
  cursor: pointer !important;
}
.fontStyleVariant7 {
  color: #707070 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant6 {
  color: #000 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03375rem !important;
}
.fontStyleVariant5 {
  color: #0354e0 !important;
  font-family: 'Russo One', sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.fontStyleVariant4 {
  color: #2889e5 !important;
  text-align: center !important;
  font-family: 'Russo One', sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.fontStyleVariant2 {
  color: #707070 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant1 {
  color: var(--primary-black, #000) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.0375rem !important;
}
.loginCardGapVariant1 {
  gap: 2rem !important;
}
.loginCardTextVariant4 {
  color: var(--secondary-slate, #707070) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.02625rem !important;
}
.loginCardTextVariant3 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.loginCardTextVariant2 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100% !important; /* 19.0.125rem */
  letter-spacing: -0.03rem !important;
}
.loginCardTextVariant1 {
  color: var(--primary-black, #000) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 90% !important;
  letter-spacing: -0.0525rem !important;
}
.loginCard {
  display: flex !important;
  width: 29.5rem !important;
  height: 31.25rem !important;
  padding: 2.5rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 2rem !important;
  flex-shrink: 0 !important;
}
.BankLogoImage {
  color: #2889e5 !important;
  text-align: center !important;
  font-family: 'Russo One', sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 1.875rem !important;
}
.XchangeLogoImage {
  color: #0354e0 !important;
  font-family: 'Russo One', sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.croppa-container {
  width: 100% !important; /* Make the container fill the width of the column */
}
</style>