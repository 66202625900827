<template>
  <v-overlay :value="overlay" opacity="0.5">
    <v-img src="@/assets/loader1.gif" contain max-width="150"></v-img>
    <div class="text-center mt-3">Loading Data...!</div>
  </v-overlay>
</template>
<script>
export default {
  name: "overlayComp",
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
