import axios from "axios";

export const RefreshToken = {
  data: () => ({}),
  methods: {
    async GetRefreshTokenMethod() {
      try {
        this.loading = true;
        let data = JSON.stringify({
          refresh_token:
            this.$store.getters.get_token_details.AuthenticationResult
              .RefreshToken,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://1m7x2kkvsd.execute-api.us-east-1.amazonaws.com/dev/getsessiontoken",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "CEC2oWjNP5aF1qsJvJwYv1MD39vd3pSM7btsyox3",
          },
          data: data,
        };
        let result = await axios(config);
        console.log('Result for Refrsh TOken',result)
       return result;
      } catch (error) {
        this.loading = false;
        console.log("Error", error);
      }
    },
  },
};
