<template>
  <v-app>
    <v-card-text>
      <Snackbar :SnackBarComponent="SnackBarComponent" />
      <v-card
        :height="windowHeight"
        color="blueColorVariant1"
        class="overflow-hidden"
      >
        <v-toolbar dense flat tile color="transparent">
          <div class="pt-13">
            <v-img contain width="220px" src="@/assets/MbcaLogo.png" />
          </div>
          <v-spacer />
        </v-toolbar>
        <v-container fluid fill-height class="mt-n6">
          <v-layout align-center justify-center>
            <v-flex>
              <v-row no-gutters class="mt-n16">
                <v-col cols="12" align="center">
                  <v-card class="loginCard">
                    <div class="loginCardTextVariant1 text-left">
                      bankXchange
                    </div>
                    <div class="loginCardTextVariant1 text-left mt-n6">
                      BMS Login {{ GetCurrentEnvironmentName }}
                    </div>
                    <div class="loginCardTextVariant2 text-left mt-n4">
                      Please login with your credentials in order to get started
                    </div>
                    <v-card-text class="pa-0 mt-n6">
                      <v-window v-model="step">
                        <v-window-item :value="1">
                          <v-card flat>
                            <v-form
                              ref="form"
                              lazy-validation
                              class="text-left"
                            >
                              <div class="loginCardTextVariant3 mb-2">
                                Email Address
                              </div>
                              <v-text-field
                                outlined
                                dense
                                height="48px"
                                class="fontFamilyInter textFieldVariant1"
                                background-color="white"
                                :rules="[(v) => !!v || 'Email ID is required']"
                                v-model="Login.EmailID"
                              >
                              </v-text-field>

                              <div class="loginCardTextVariant3 mb-2">
                                Password
                              </div>
                              <v-text-field
                                outlined
                                dense
                                height="48px"
                                :type="isPwd ? 'text' : 'password'"
                                background-color="white"
                                class="fontFamilyInter textFieldVariant1"
                                :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                v-model="Login.Password"
                                @click:append="isPwd = !isPwd"
                              >
                              </v-text-field>
                              <div class="loginCardTextVariant4">
                                "By continuing, you agree to the
                                <span class="blueColorVariant1--text"
                                  >Terms & Conditions.</span
                                >"
                              </div>
                            </v-form>
                          </v-card>
                          <v-card-actions class="px-0 py-4">
                            <v-btn
                              :loading="loading"
                              tile
                              depressed
                              dark
                              class="buttonClassVariant1 text-capitalize"
                              @click="ValidateSignInForm()"
                              >Login</v-btn
                            >
                          </v-card-actions>
                          <v-card-actions class="py-0 mt-n2">
                            <div class="fontStyleVariant9" @click="step = 3">
                              Forgot Password ?
                            </div>
                          </v-card-actions>
                        </v-window-item>

                        <!-- End Of step 1 -->
                        <!-- Start Of step 2 -->
                        <v-window-item :value="2">
                          <v-card-text class="pa-0 text-left">
                            <v-card flat>
                              <v-form ref="formCP" lazy-validation>
                                <div class="loginCardTextVariant3 mb-2">
                                  Enter New Password
                                </div>
                                <v-text-field
                                  flat
                                  dense
                                  outlined
                                  height="48px"
                                  class="fontFamilyInter textFieldVariant1"
                                  :type="isPwdNew ? 'text' : 'password'"
                                  @click:append="isPwdNew = !isPwdNew"
                                  required
                                  :append-icon="
                                    isPwdNew ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  prepend-inner-icon="mdi-lock"
                                  :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]"
                                  v-model="Password.NewPassword"
                                ></v-text-field>
                                <div class="loginCardTextVariant3 mb-2">
                                  Confirm New Password
                                </div>
                                <v-text-field
                                  flat
                                  dense
                                  outlined
                                  height="48px"
                                  class="fontFamilyInter textFieldVariant1"
                                  :type="isPwdConfirm ? 'text' : 'password'"
                                  @click:append="isPwdConfirm = !isPwdConfirm"
                                  required
                                  :append-icon="
                                    isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  prepend-inner-icon="mdi-lock"
                                  :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]"
                                  v-model="Password.ConfirmPassword"
                                ></v-text-field>
                              </v-form>
                            </v-card>
                          </v-card-text>
                          <v-card-actions class="pt-0 pb-8 px-4">
                            <v-btn
                              dark
                              block
                              :loading="loading"
                              tile
                              class="buttonClassVariant1 text-capitalize"
                              @click="ValidateConfirmPasswordForm()"
                              >Login</v-btn
                            >
                          </v-card-actions>
                        </v-window-item>
                        <!-- End Of step 2 -->
                        <!-- Start Of step 3 -->
                        <v-window-item :value="3">
                          <v-card-text class="pa-0 text-left">
                            <v-form ref="formOTP">
                              <div class="loginCardTextVariant3 text-left mb-2">
                                Please Enter The Registered Email
                              </div>
                              <v-text-field
                                dense
                                outlined
                                height="48px"
                                class="fontFamilyInter textFieldVariant1"
                                label="Email"
                                v-model="OTPEmail"
                                :rules="[(v) => !!v || 'Email ID is required']"
                              >
                              </v-text-field>
                              <div class="loginCardTextVariant4">
                                Please enter the e-mail used for bankXchange
                                login.
                              </div>
                            </v-form>
                          </v-card-text>
                          <v-card-actions class="pl-0 mt-4">
                            <div class="fontStyleVariant9" @click="step = 1">
                              Back To Sign In
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              dark
                              class="buttonClassVariant1 text-capitalize"
                              small
                              :loading="loadingSendOTP"
                              @click="ValidateOTP()"
                              >Next</v-btn
                            >
                          </v-card-actions>
                        </v-window-item>
                        <!-- End Of step 3 -->
                        <!-- Start Of Step 4 -->
                        <v-window-item :value="4">
                          <v-card-text class="pa-0">
                            <v-form ref="formConfirmOTP">
                              <div class="loginCardTextVariant3 text-left mb-2">
                                Enter the bankXchange Activation Code
                              </div>
                              <div class="text-center"></div>
                              <v-text-field
                                flat
                                dense
                                outlined
                                height="48px"
                                class="fontFamilyInter textFieldVariant1"
                                v-model="ConfirmOTP"
                                :rules="[(v) => !!v || 'Code is required']"
                              >
                              </v-text-field>
                              <div class="loginCardTextVariant3 text-left mb-2">
                                Enter New Password
                              </div>
                              <v-text-field
                                flat
                                dense
                                outlined
                                height="48px"
                                class="fontFamilyInter textFieldVariant1"
                                v-model="ConfirmNewPassword"
                                :rules="[(v) => !!v || 'Password is required']"
                                :type="isPwdConfirmCode ? 'text' : 'password'"
                                @click:append="
                                  isPwdConfirmCode = !isPwdConfirmCode
                                "
                                required
                                :append-icon="
                                  isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                prepend-inner-icon="mdi-lock"
                              >
                              </v-text-field>
                              <div class="loginCardTextVariant4 text-left">
                                Enter the bankXchange Activation Code sent to
                                your registered Email.
                              </div>
                            </v-form>
                          </v-card-text>
                          <v-card-actions>
                            <div class="fontStyleVariant9" @click="step = 1">
                              Back To Sign In
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              dark
                              class="buttonClassVariant1 text-capitalize"
                              small
                              :loading="loadingConfirmForgotPassword"
                              @click="ValidateEnteredOTP()"
                              >Confirm</v-btn
                            >
                          </v-card-actions>
                        </v-window-item>
                        <!-- End Of Step 4-->
                      </v-window>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-card-text>
  </v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import { GetUniqueId } from "@/Mixins/MultiFactorAuthentication/GetUniqueId.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
// import axios from "axios";
export default {
  components: {
    Snackbar,
  },
  mixins: [GetUniqueId],
  data: () => ({
    step: 1,
    height: 0,
    windowHeight: 0,

    OTPEmail: "",
    ConfirmOTP: "",
    current_header: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",
    unique_id: "",
    SessionKey: "",

    isPwd: false,
    loading: false,

    isPwdNew: false,
    isPwdConfirm: false,
    loadingEmail: false,
    loadingPassword: false,
    loadingSendOTP: false,
    isLoginSelected: true,
    isPwdConfirmCode: false,
    loadingConfirmForgotPassword: false,

    Login: {
      EmailID: "",
      Password: "",
    },

    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },

    sign_up: {
      team_name: "",
      user_email_id: "",
      user_name: "",
      user_mobile_number: "",
      user_country_code: "+91",
    },
    SnackBarComponent: {},

    MenuItems: [
      "about",
      "members",
      "bankXchange",
      "community",
      "issues",
      "newsroom",
      "contact",
    ],
  }),
  watch: {},
  computed: {
    GetCurrentEnvironmentName() {
      switch (window.location.hostname) {
        case "devbms.bankxchange.com":
          return "- DEV";
        case "testbms.bankxchange.com":
          return "- TEST";
        case "uatbms.bankxchange.com":
          return "- UAT";
        case "bms.bankxchange.com":
          return "";
      }
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Customer - Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        case 5:
          return "Reset Expired Password";
        default:
          return "Account created";
      }
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight - 35;
    this.unique_id = this.getUniqueIdMethod();
    console.log("Unique id", this.unique_id);
  },
  methods: {
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async ConfirmOTPMethod() {
      this.loadingConfirmForgotPassword = true;
      Auth.forgotPasswordSubmit(
        this.OTPEmail,
        this.ConfirmOTP,
        this.ConfirmNewPassword
      )
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.loadingConfirmForgotPassword = false;
          this.$refs.formConfirmOTP.reset();

          this.step = 1;
        })
        .catch((err) => {
          this.loadingConfirmForgotPassword = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.toString().split(":")[1],
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        this.ConfirmOTPMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    async SendOTPEmail() {
      this.loadingSendOTP = true;
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.step = 4;
          this.loadingSendOTP = false;
        })
        .catch((err) => {
          this.loadingSendOTP = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.toString().split(":")[1],
          };
        });
    },
    async ConfirmPassword() {
      // try {
      //   this.loading = true;
      //   let data = JSON.stringify({
      //     user_email_id: this.Login.EmailID,
      //     user_password: this.Password.ConfirmPassword,
      //     user_session: this.SessionKey,
      //   });
      //   let config = {
      //     method: "post",
      //     maxBodyLength: Infinity,
      //     url: "https://1m7x2kkvsd.execute-api.us-east-1.amazonaws.com/dev/confirmpassword",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-api-key": "CEC2oWjNP5aF1qsJvJwYv1MD39vd3pSM7btsyox3",
      //     },
      //     data: data,
      //   };
      //   let result = await axios(config);
      //   if (result.status === 200) {
      //     this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
      //     this.$store.commit("SET_TOKEN_DETAILS", result.data);
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "green",
      //       Top: true,
      //       SnackbarText: "Login Successful",
      //     };
      //     this.ActivateMethod();
      //     this.loading = false;
      //   }
      // } catch (error) {
      //   this.loading = false;
      //   console.log("Error", error);
      // }
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(
          this.user,
          this.Password.ConfirmPassword
        )
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.toString().split(":")[1],
        };
      }
    },
    ValidateSignInForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async SignIn() {
      // try {
      //   this.loading = true;
      //   let data = JSON.stringify({
      //     user_email_id: this.Login.EmailID,
      //     user_password: this.Login.Password,
      //   });
      //   let config = {
      //     method: "post",
      //     maxBodyLength: Infinity,
      //     url: "https://1m7x2kkvsd.execute-api.us-east-1.amazonaws.com/dev/signIn",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-api-key": "CEC2oWjNP5aF1qsJvJwYv1MD39vd3pSM7btsyox3",
      //     },
      //     data: data,
      //   };
      //   let result = await axios(config);
      //   console.log("Sign In Result", result);
      //   if (result.data.errorType !== "Error") {
      //     if (result.data.challengeName === "NEW_PASSWORD_REQUIRED") {
      //       this.step = 2;
      //       this.SessionKey = result.data.Session;
      //     } else {
      //       this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
      //       this.$store.commit("SET_TOKEN_DETAILS", result.data);
      //       this.SnackBarComponent = {
      //         SnackbarVmodel: true,
      //         SnackbarColor: "green",
      //         Top: true,
      //         SnackbarText: "Login Successful",
      //       };
      //       this.ActivateMethod();
      //       this.loading = false;
      //     }
      //   } else {
      //     this.loading = false;
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       Top: true,
      //       SnackbarText: result.data.errorMessage,
      //     };
      //   }
      // } catch (error) {
      //   this.loading = false;
      //   console.log("Error", error);
      // }
      try {
        this.loading = true;
        this.user = await Auth.signIn(this.Login.EmailID, this.Login.Password);
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        console.log("Errror", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In!!",
      };
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
  },
};
</script>

<style></style>
