import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    EmailID: "",
    TokenDetails: {},
    GetCurrentUser: [],
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_token_details: (state) => {
      return state.TokenDetails;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_TOKEN_DETAILS(state, TokenDetails) {
      state.TokenDetails = TokenDetails;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
