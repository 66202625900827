import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
Vue.config.productionTip = false;
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "devbms.bankxchange.com" ||
      window.location.hostname === "localhost"
        ? "us-east-1_EMP5gZeVx"
        : window.location.hostname === "testbms.bankxchange.com"
        ? "us-east-1_l96ZntSG5"
        : window.location.hostname === "uatbms.bankxchange.com"
        ? "us-east-1_da50Lud2f"
        : "us-east-1_ASDduNdXa",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "devbms.bankxchange.com" ||
      window.location.hostname === "localhost"
        ? "1ntl9g6l888evn0a1p6sa07beq"
        : window.location.hostname === "testbms.bankxchange.com"
        ? "142jvp33ov4hd94tflp24l743a"
        : window.location.hostname === "uatbms.bankxchange.com"
        ? "75a1khqd23racsf9slde6vo4nj"
        : "12eabtisi1ua8dimvhbkqj7e6k",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "devbms.bankxchange.com" ||
      window.location.hostname === "localhost"
        ? "https://sih26dzgf5e2tkhzoo362h4hsu.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "testbms.bankxchange.com"
        ? "https://t4ytnfv6ezbrxmr56eookc4nsy.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uatbms.bankxchange.com"
        ? "https://g7dizbkqznct5grtcj2gsafuaq.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://vw7yxjtptzdjvkjd5em76tqss4.appsync-api.us-east-1.amazonaws.com/graphql",

    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
console.log("hii from main.js", window.location.hostname);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
